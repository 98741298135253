<template>
    <div>
        <modal name="notify"
            height="auto"
            styles="background-color: transparent; box-shadow: none; width: 100%; padding: 15px"
            width="100%"
            :maxWidth="900"
            :adaptive="true"
            :scrollable="true"
        >
            <div style="text-align: right; margin-right: -10px;">
                <a href="javascript:;" @click="$modal.hide('notify')">
                    <svg width="40px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M178.3 178.3C181.5 175.2 186.5 175.2 189.7 178.3L256 244.7L322.3 178.3C325.5 175.2 330.5 175.2 333.7 178.3C336.8 181.5 336.8 186.5 333.7 189.7L267.3 256L333.7 322.3C336.8 325.5 336.8 330.5 333.7 333.7C330.5 336.8 325.5 336.8 322.3 333.7L256 267.3L189.7 333.7C186.5 336.8 181.5 336.8 178.3 333.7C175.2 330.5 175.2 325.5 178.3 322.3L244.7 256L178.3 189.7C175.2 186.5 175.2 181.5 178.3 178.3V178.3zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 16C123.5 16 16 123.5 16 256C16 388.5 123.5 496 256 496C388.5 496 496 388.5 496 256C496 123.5 388.5 16 256 16z" /></svg>
                </a>
            </div>
            <div v-html="notifications[0].message" style="background-color: #FFF; box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;"></div>
            <div style="margin-top: 15px; background-color: #FFF; box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px; border-spacing: 0px; text-align: left;">
                <label style="padding: 10px 20px; display: inline-block;">
                    <input id="not-show-this-message-novelties" @click="noShow" type="checkbox"/> No volver a mostrar este mensaje
                </label>
            </div>
        </modal>
    </div>
</template>

<script>
export default {
  name: 'Novelties',
  props: {
    customer_token: null,
    product_token: null
  },
  data(){
    return {
      notifications: [{message: ''}],
      no_display: null
    }
  },
  mounted () {
      this.viewed()
      this.getNovelties()
  },
  methods: {
    getNovelties(){
      this.axios.get('novelties', {params: {customer_token: this.customer_token, product_token: this.product_token}}).then(response => {
         if (localStorage.no_display) {
          if(localStorage.no_display != response.data[0].id){
            if(response.data[0]){
              this.notifications = response.data
              this.$modal.show('notify')
            }
          }
         }else{
          if(response.data[0]){
            this.notifications = response.data
            this.$modal.show('notify')
          }
         }
      })
    },
    noShow(){
      this.no_display = this.notifications[0].id
      this.$modal.hide('notify')
    },
    viewed () {
      if (localStorage.is_viewed) {
        this.no_display = localStorage.no_display;
      }
    }
    
  },
  watch: {
    no_display(view) {
      localStorage.no_display = view;
    }
  }
}
</script>

<style>
    mobysuite-novelties .vm--modal {
        overflow: visible !important;
        width: 80%;
    }
    mobysuite-novelties img {
        width: 100%;
        height: auto;
    }
    mobysuite-novelties .btn-close-novelties{
        width: 30%;
        text-align: center;
        background-color:#4DB9CE;
        color:#FFFFFF;
        cursor: pointer;
        font-weight: 700;
    }
    mobysuite-novelties .btn-close-novelties:hover{
        background-color:#ff671d;
    }
</style>
