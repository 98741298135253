import Vue from 'vue'
import App from './App.vue'
import VModal from 'vue-js-modal'
import axios from 'axios'
import VueAxios from 'vue-axios'
import vueCustomElement from 'vue-custom-element'

const instance = axios.create({
	baseURL: process.env.VUE_APP_URL_INTEGRATION,
})

Vue.use(VueAxios, instance)
Vue.use(vueCustomElement)

Vue.config.productionTip = false
Vue.use(VModal, { componentName: 'modal' })

Vue.customElement('mobysuite-novelties', App)