<template>
  <div id="app">
    <Novelties :customer_token="this.customer_token" :product_token="this.product_token" />
  </div>
</template>

<script>
import Novelties from './components/Novelties.vue'

export default {
  name: 'App',
  components: {
    Novelties
  },
  props:{
    customer_token: { type: String, default: null },
		product_token: { type: String, default: null },
  }
}
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
